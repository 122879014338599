import React from 'react'
import createFragment from 'react-addons-create-fragment'

import locales from '../utils/strings';
import styles from '../templates/basic.module.less';
import localStyles from './news.module.less';
import Layout from "../components/layout";

import siteInfo from '../../content/info.js';
import {newsEntries, processNewsEntry} from '../utils/newsEntries';

const IndexPage = ({ location, pathContext }) => {
  const primaryLang = siteInfo.languages[0]
  const {lang = primaryLang} = pathContext

  if (!locales[lang]) {
    throw new Error(`Unknown language: ${lang}.`);
  }

  const strings = locales[lang].news

  const ents = newsEntries[lang]
    .map(e => processNewsEntry(lang, e))
    .map(({date, headline}, i) => createFragment({
      date: <dt>{date}</dt>,
      headline: <dd dangerouslySetInnerHTML={headline} />,
    }))

  return <Layout location={location}>
    <div className={localStyles.root}>
      <h1 className={styles.title}>{strings.title}</h1>
      <dl>{ents}</dl>
    </div>
  </Layout>
}

export default IndexPage
